/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"

const addFBMessenger = (d, s, id) => {
  var js,
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) return
  js = d.createElement(s)
  js.id = id
  js.src = "https://connect.facebook.net/vi_VN/sdk/xfbml.customerchat.js"
  fjs.parentNode.insertBefore(js, fjs)
}

export const onClientEntry = () => {
  window.onload = () => {
    window.fbAsyncInit = () => {
      window.FB.init({
        xfbml: true,
        version: "v10.0",
      })
    }
    addFBMessenger(document, "script", "facebook-jssdk")
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {element}

      <div id="fb-root"></div>
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id="627426527407876"
        theme_color="#bf2e29"
        logged_in_greeting="Xin chào! GCP có thể giúp gì được cho bạn?"
        logged_out_greeting="Xin chào! GCP có thể giúp gì được cho bạn?"
      ></div>
    </>
  )
}
