// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bang-gia-js": () => import("./../../../src/pages/bang-gia.js" /* webpackChunkName: "component---src-pages-bang-gia-js" */),
  "component---src-pages-chinh-sach-bao-mat-mdx": () => import("./../../../src/pages/chinh-sach-bao-mat.mdx" /* webpackChunkName: "component---src-pages-chinh-sach-bao-mat-mdx" */),
  "component---src-pages-dieu-khoan-dich-vu-mdx": () => import("./../../../src/pages/dieu-khoan-dich-vu.mdx" /* webpackChunkName: "component---src-pages-dieu-khoan-dich-vu-mdx" */),
  "component---src-pages-doi-tac-js": () => import("./../../../src/pages/doi-tac.js" /* webpackChunkName: "component---src-pages-doi-tac-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lien-he-mdx": () => import("./../../../src/pages/lien-he.mdx" /* webpackChunkName: "component---src-pages-lien-he-mdx" */)
}

